import { Input } from 'antd';
import get from 'lodash/get';
import { FC, ReactNode, useEffect } from 'react';
import { Controller, useFormContext, FieldError } from 'react-hook-form';
import { Field } from './field';


type TextAreaFieldEditProps = {
  name: string;
  label: ReactNode;
  value?: string | null;
  required?: boolean;
};

export const TextAreaField: FC<TextAreaFieldEditProps> = ({ name, label, value, required = false, }) => {
  const { control } = useFormContext();
  const {
    setValue,
    register,
    unregister,
    formState: { errors },
  } = useFormContext();

  useEffect(() => {
    register(name, { required: { value: required, message: 'Cannot be empty' } });
    setValue(name, value);
    return () => unregister(name);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name, value]);

  const error = get(errors, name) as FieldError | undefined;


  return (
    <Field label={label} required={required} validateStatus={error ? 'error' : ''} help={error?.message}>
      <Controller
        name={name}
        control={control}
        render={({ field }) => <Input.TextArea {...field} autoSize={{ minRows: 3 }} />}
        defaultValue={value || ''}
      />
    </Field>
  );
};
