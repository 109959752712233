import { Button, Modal } from 'antd';
import React, { useState } from 'react';
import { useForm, FormProvider } from "react-hook-form";
import { rejectUser } from '../Shared/data';

import { CloseCircleOutlined } from '@ant-design/icons';
import { TextAreaField } from './Form/textAreaField';
type RejectUserType = {
    userId: string
}

const RejectUser: React.FC<RejectUserType> = ({userId}) => {
    const [isModalOpen, setIsModalOpen] = useState(false);

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const methods = useForm();
    const onSubmit = async (data: any) => {
        
        const rejectionStatus = await rejectUser(userId, data.reason)
        setIsModalOpen(false);
        if (rejectionStatus  && rejectionStatus.status=== 200) {
            Modal.success({
                content: 'User Rejected!',
            });
            window.location.href = '/'
            
        } else {
            Modal.error({
                content: 'Failed to reject User'
            })
        }
       
    };

    return (
        <>
            <Button style={{ marginTop: '20px' }}
                icon={<CloseCircleOutlined />}
               
                onClick={showModal}
            >
                Reject 
            </Button>
            <Modal title="Reject User" open={isModalOpen} onOk={methods.handleSubmit(onSubmit)} onCancel={handleCancel} okText="Reject User">
                <form className="form" onSubmit={methods.handleSubmit(onSubmit)}>
                    <FormProvider {...methods}>

                        <TextAreaField name="reason" label="Reason" value="" required={true} />
                    </FormProvider>
                </form>
            </Modal>
        </>
    )
}

export default RejectUser