import { Button, Modal } from 'antd';
import React, { useState } from 'react';
import { useForm, FormProvider } from "react-hook-form";
import { postNewCompany } from '../Shared/data';
import { CompanyType } from '../types/nDAtype';
import { companyDefaultValue } from '../Utils/defaultValue';
import { InputField } from './Form/InputField';
import { PlusCircleOutlined } from '@ant-design/icons';
type AddCompanyType = {
    rbuName: string,
    isCompanyAdded: (arg: boolean) => void
}

const AddCompany: React.FC<AddCompanyType> = ({ rbuName, isCompanyAdded }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const methods = useForm({ defaultValues: companyDefaultValue });
    const onSubmitCompany = async (data: CompanyType) => {
        const companyStatus = await postNewCompany(data.name, rbuName)
        setIsModalOpen(false);
        if (companyStatus === 200) {
            Modal.success({
                content: 'New Company added!',
            });
            isCompanyAdded(true);
        } else {
            Modal.error({
                content: 'Failed to add new Company'
            })
        }

    };

    return (
        <>
            <Button style={{ marginTop: '20px' }}
                icon={<PlusCircleOutlined />}
                type="primary"
                className='button-primary'
                onClick={showModal}
            >
                Add Company
            </Button>
            <Modal title="New Company" open={isModalOpen} onOk={methods.handleSubmit(onSubmitCompany)} onCancel={handleCancel} okText="Add Company">
                <form className="form" onSubmit={methods.handleSubmit(onSubmitCompany)}>
                    <FormProvider {...methods}>

                        <InputField name="name" label="Company" value="" required maxLength={255} />
                    </FormProvider>
                </form>
            </Modal>
        </>
    )
}

export default AddCompany