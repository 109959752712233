import React from "react";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../../../authConfig";
import { Button } from "antd";


/**
 * Renders a drop down button with child buttons for logging in with a popup or redirect
 */
export const SignInButton = () => {
    const { instance } = useMsal();

    const handleLogin = (loginType:any) => {
        if (loginType === "popup") {
            instance.loginPopup(loginRequest).catch(e => {
            });
        } else if (loginType === "redirect") {
            instance.loginRedirect(loginRequest).catch(e => {
            });
        }
    }
    return (
        <Button onClick={() => handleLogin("redirect")}>Sign In</Button>
        // <Dropdown  > Sign In
        //     <Dropdown.Button  onClick={() => handleLogin("popup")}>Sign in using Popup</Dropdown.Button>
        //     <Dropdown.Button onClick={() => handleLogin("redirect")}>Sign in using Redirect</Dropdown.Button>
        // </Dropdown>
    )
}