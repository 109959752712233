import {Form, Avatar, Row, Col, UploadProps} from 'antd';
import {FormItemProps} from 'antd/lib/form';
import {FC, ReactNode} from 'react';

import './field.css';

type FieldProps = {
  icon?: ReactNode;
};

export const Field: FC<FieldProps & FormItemProps & UploadProps> = ({children, icon, ...formItemProps}) => (
  <Row className="field">
    <Col>{icon && <Avatar icon={icon} className="field__icon" size="default" />}</Col>
    <Col flex="1">
      <Form.Item {...formItemProps} colon={false}>
        {children}
      </Form.Item>
    </Col>
  </Row>
);
