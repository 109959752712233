import {Select} from 'antd';
import {SelectProps} from 'antd/lib/select';
import {FC, ReactNode, useEffect} from 'react';
import {useFormContext, Controller} from 'react-hook-form';
import { Field } from './field';


type SelectFieldEditProps<T> = {
  name: string;
  label: ReactNode;
  defaultValue?: T;
  value?: T;
  options: SelectProps<T>['options'];
  disabled?: boolean;
  allowClear?: boolean;
  placeholder?: string;
};

export function SelectField<T extends string>({
  name,
  label,
  defaultValue,
  value,
  options,
  disabled = false,
  allowClear = false,
  placeholder,
}: SelectFieldEditProps<T>): ReturnType<FC<SelectFieldEditProps<T>>> {
  const {setValue, register, unregister,control} = useFormContext();
  useEffect(() => {
    register(name);
    return () => unregister(name);
  }, [name, register, unregister]);

  useEffect(() => {
    setValue(name, value ?? defaultValue);
  }, [name, value, setValue, unregister, defaultValue]);

  return (
    <Field
      label={label}>
      
      <Controller
        name={name}
        control={control}
        render={({field}) => <Select {...field} options={options} showSearch/>}
        defaultValue={value || defaultValue}
      />
    </Field>
  );
}
