import {FC} from 'react';
import {  Result } from 'antd';
import { LockOutlined } from '@ant-design/icons';


export const UnAuthenticatedPage:FC =()=>(
    <Result
    icon={<LockOutlined />}
    subTitle="Sorry, you are not authenticated to access this page. Sign In to see web information!"
   
  />
)