import {FC} from 'react';
import {  Result } from 'antd';
import { LockOutlined } from '@ant-design/icons';


export const UnAuthorizedPage:FC =()=>(
    <Result
    icon={<LockOutlined />}
    subTitle="Sorry, you are not authorized to access this page. If you would like to access contact your Sales representative!"
   
  />
)