import { Upload,message } from 'antd';

import type { UploadProps } from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import get from 'lodash/get';
import {FC, ReactNode, useEffect} from 'react';
import {Controller, FieldError, useFormContext} from 'react-hook-form';

import {Field} from './field';

const { Dragger } = Upload;
type FileUploadProps = {
  name: string;
  value?: string;
  defaultValue?: string;
  label: ReactNode;
  required?: boolean;
  maxLength?: number;
  tooltip?: ReactNode;
  readonly?: boolean;
  multiple?:boolean;
};

export const FileUpload: FC<FileUploadProps> = ({
  name,
  label,
  value,
  defaultValue,
  multiple = false,
  tooltip,
}) => {
  const {control} = useFormContext();
  const {
    setValue,
    register,
    unregister,
    formState: {errors},
  } = useFormContext();

  useEffect(() => {
    register(name, );
    setValue(name, value ?? defaultValue);
    return () => unregister(name);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name, value]);

  const error = get(errors, name) as FieldError | undefined;
  const props: UploadProps = {
    multiple: false,
    name:'file',
    action: '/api/upload',
    
   
    onChange(info) {
      const { status } = info.file;
      if (status === 'done') {
        setValue(name,info.file.response)
        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    onDrop(e) {
      console.log('Dropped files', e.dataTransfer.files);
    },
  };

  return (
    <Field
      label={label}
      validateStatus={error ? 'error' : ''}
      help={error?.message}
      tooltip={tooltip}>
      <Controller
        name={name}
        control={control}
        render={({field}) => <Dragger {...props} maxCount={1}><p className="ant-upload-drag-icon">
        <InboxOutlined />
      </p>
      <p className="ant-upload-text">Click or drag file to this area to upload</p>
      <p className="ant-upload-hint">
        Support for a single  upload. 
      </p>
    </Dragger>}
       defaultValue={value || defaultValue}
      />
    </Field>
  );
};
