import React, { useEffect, useState } from 'react';

import { Button, message, Modal, Table, Tag } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { UserAddOutlined, ExclamationCircleOutlined, CheckCircleOutlined } from '@ant-design/icons';
import { defaultUser } from '../Utils/defaultValue';
import {  UserDetailType } from '../types/nDAtype';
import { addApplicationUser, getRequestedUsers } from '../Shared/data';
import { Content } from 'antd/es/layout/layout';
import RejectUser from '../Components/RejectUser';

type DetailType = {
    isApprovable?: boolean
}
const UserDetail: React.FC<DetailType> = ({isApprovable}) => {
   
    const columns: ColumnsType<any> = [
       
        {
            title: 'Full Name',
            dataIndex: 'full name',
            key: 'name',
            render: ( text, record) => (
                <span><a href ={`/nda/${record.id}`} className="text-normal">{record.name} {record.surname} </a> </span>
              )

        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
        },
        {
            title: 'Company',
            dataIndex: 'company',
            key: 'company',
        },
        {
            title: 'Level',
            dataIndex: 'roles',
            key: 'roles',
            render: (roles: string[]) =>
            (
                <span>{roles && roles.map((role) => {
                    return (<Tag key={role}>{role}</Tag>)
                })}</span>
            )
        },
        {
            title: 'RBU',
            dataIndex: 'rbu',
            key: 'rbu',
        },
        {
            title: 'SubmitterEmail',
            dataIndex: 'requester',
            key: 'requester'
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key:'status'
    },
        {
            title: 'Action',
            render: (_, record) => {
                return (
                    <>{isApprovable && <Button onClick={() => approveUser(record)}><CheckCircleOutlined />Approve</Button>} &nbsp;
                   {isApprovable &&  <RejectUser userId={record.id}/> }
                      </>

                )
            }
        }
    ];

    const [requestedUsers, setRequestedUsers] = useState<UserDetailType[]>(defaultUser)
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [modaldata, setmodaldata] = useState<any[]>([]);
    const[userEmail, setUserEmail] = useState("");

    const [messageApi, contextHolder] = message.useMessage();
 
    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const getData = async () => {
        try {
            let actualData = await getRequestedUsers();
            let filteredData = actualData.filter((user:any) =>user.status !=="Accepted")
           
           
            setRequestedUsers(filteredData);

        } catch (err) {

            setRequestedUsers(defaultUser);
        }
    }
    useEffect(() => {
        getData();
    }, [])

    const dataSource = requestedUsers.map((requestedUser: UserDetailType) => {
        return ({ ...requestedUser, key: requestedUser.id })
    })

    const handleOk = async () => {
        
        setIsModalVisible(false);
        let newApplicationUser = await addApplicationUser(modaldata);
        if ( newApplicationUser?.status === 200) {
            window.location.href = '/'
        } 
    };

    const approveUser = (data: any) => {
        setUserEmail(data.email);
        setIsModalVisible(true)
      
        setmodaldata(data);
  }

    return (
        <Content className="site-layout" style={{ padding: '0 50px', marginTop: '40px' }}>
             {contextHolder}
            <div className='flex-container-end'>
                <div className='flex-item'>
                    <Button
                        icon={<UserAddOutlined />}
                        type="primary"
                        className='button-primary'
                        onClick={event => window.location.href = '/nda'}
                    >
                        Request New User
                    </Button>
                </div>
            </div>
            <Table

                columns={columns}
                dataSource={dataSource}

            />
            <Modal
                title={[<><ExclamationCircleOutlined/> <span>Confirm</span></>]}
                open={isModalVisible}
                onOk={handleOk}
                onCancel={handleCancel}
            > <p>Are you sure to approve  user {userEmail}?</p></Modal>
        </Content>
    );
};

export default UserDetail;