import {Input} from 'antd';
import get from 'lodash/get';
import {FC, ReactNode, useEffect} from 'react';
import {Controller, FieldError, useFormContext} from 'react-hook-form';

import {Field} from './field';

type TextFieldProps = {
  name: string;
  label: ReactNode;
  value?: string;
  defaultValue?: string;
  required?: boolean;
  maxLength?: number;
  tooltip?: ReactNode;
  readonly?: boolean
};

export const InputField: FC<TextFieldProps> = ({
  name,
  label,
  value,
  defaultValue,
  required = false,
  readonly = false,
  maxLength,
  tooltip,
}) => {
  const {control} = useFormContext();
  const {
    setValue,
    register,
    unregister,
    formState: {errors},
  } = useFormContext();

  useEffect(() => {
    register(name, {required: {value: required, message: 'Cannot be empty'}});
    setValue(name, value ?? defaultValue);
    return () => unregister(name);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name, value]);

  const error = get(errors, name) as FieldError | undefined;

  return (
    <Field
      label={label}
      required={required}
      validateStatus={error ? 'error' : ''}
      help={error?.message}
      tooltip={tooltip}>
      <Controller
        name={name}
        control={control}
        render={({field}) => <Input {...field} maxLength={maxLength} readOnly={readonly} />}
        defaultValue={value || defaultValue}
      />
    </Field>
  );
};
