import React from 'react';
import { Route, Routes } from 'react-router-dom';
import './App.css';
import './Styles/style.css'
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from "@azure/msal-react";

import { AppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { reactPlugin } from './applicationInsightsService';

import { PageLayout } from './Components/Form/PageLayout';


import NDA from './Pages/Nda';
import UserDetail from './Pages/userDetail';
import { UnAuthorizedPage } from './Pages/unAuthorizedPage';
import { isDevEnv, isProdEnv } from './Shared/environments';
import { UnAuthenticatedPage } from './Pages/unAunthenticatedPage';
import { appRoles } from './authConfig';

const MainContent = () => {
    const {  accounts  } = useMsal();

    const currentRole = accounts[0]? accounts[0].idTokenClaims?.roles: [];
    
    const canApprove = currentRole?.includes(appRoles.UserApprove);
    const canView = currentRole?.includes(appRoles.UserView);
    const validRole = canApprove || canView
    return (
        <div >
            <AuthenticatedTemplate>
               {validRole ?(
             
                <Routes>
                    <Route path='/' element={<UserDetail isApprovable={canApprove}/>} />
                    <Route path='/nda' element={<NDA  requestorEmail = {accounts[0]?accounts[0].username:''}/>} />
                    <Route path='/nda/:id' element={<NDA  requestorEmail = {accounts[0]?accounts[0].username:''} canApprove={canApprove}/>} />
                    <Route path='/userDetail' element={<UserDetail isApprovable={canApprove}/>} />
                    <Route path='/unauthorized' element={<UnAuthorizedPage />} />

                </Routes>):
                (<UnAuthorizedPage/>)}
                
            </AuthenticatedTemplate>


            <UnauthenticatedTemplate>
                <UnAuthenticatedPage></UnAuthenticatedPage>
            </UnauthenticatedTemplate>
        </div>
    );
};


const App: React.FC = () => {
    
    return (      
        <PageLayout>
            {isProdEnv && 
          
             <AppInsightsContext.Provider value={reactPlugin}>
            <MainContent />
            
         
            </AppInsightsContext.Provider>}
            {isDevEnv &&  <MainContent />}
        </PageLayout>    
    );


}

export default App;