export const ndaDefaultValue = {
    firstName: "",
    surname: "",
    email: "",
    password: "",
    rbu: '',
    level: '',
    company: '',
    remarks: '',
    requestor: '',
    file: {}

}
export const companyDefaultValue = {
    name: "",
}

export const RBU = [{
    label: 'MWBR',
    value: 'mwbr'

}, {
    label: 'MWDCD',
    value: 'MWDCD'

}]

export const defaultUser: any = [
    {
        key: 1,
        id: 2556,
        userName: "",
        email: "",
        company: "",
        password: "",
        level: "",
        rBU: "",
        active: true,
        designerAccess: false,
        remarks: "",
        submitterEmail: "",
        path: "",
        file: {},
    },

];
