import React, { useEffect, useRef, useState } from 'react';
import { useForm, FormProvider } from "react-hook-form";

import { UserAddOutlined, LeftOutlined, CloudDownloadOutlined } from '@ant-design/icons';
import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { Content } from 'antd/es/layout/layout';
import { InputField } from '../Components/Form/InputField';
import { SelectField } from '../Components/Form/selectField';
import { TextAreaField } from '../Components/Form/textAreaField';
import { Button, Col, Modal, Row, Spin } from 'antd';
import { NDAType, SelectOptionType } from '../types/nDAtype';
import { ndaDefaultValue } from '../Utils/defaultValue';
import { downloadNDADocument, getCompanyList, getLevelsList, getRbuList, getRequestedUserById, postNewUser, updateExistingUser } from '../Shared/data';
import { useParams } from 'react-router-dom';
import { FileUpload } from '../Components/Form/fileUpload';

import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { isProdEnv } from '../Shared/environments';
import AddCompany from '../Components/AddCompany';
import { saveAs } from 'file-saver';


type requestorEmailType = {
    requestorEmail?: string,
    canApprove?: boolean,
}

const NDA: React.FC<requestorEmailType> = ({ requestorEmail, canApprove }) => {
    const { id } = useParams();
    const [loading, setLoading] = useState<boolean>(false);
    const [rbus, setRbus] = useState<any>([]);
    const [companies, setCompanies] = useState<any>([]);
    const [levels, setLevels] = useState<any>([]);
    const methods = useForm({ defaultValues: ndaDefaultValue });
    const { watch } = methods;
    const [requestedUserById, setRequestedUserById] = useState<any>();
    const rBUValue = watch('rbu');
    const levelValue = watch('level');
    const prevRequestorEmail = watch('requestor');
    const prevLevelValue = requestedUserById && requestedUserById.roles;
    const [isButtonDisabled, setButtonDisabled] = useState(false);

    const hasRoleChanged = JSON.stringify(levelValue) === JSON.stringify(prevLevelValue);

    const firstRender = useRef(true);
    const appInsights = useAppInsightsContext();
    const isDataValid = (data: NDAType) => data.rbu && data.level && data.company;
    const isLevelBasic = levelValue && levelValue.includes("Basic");

    const isOriginalRequestor = requestorEmail === prevRequestorEmail;

    const [companyAdded, setCompanyAdded] = useState(false);

    const isCompanyAdded = (status: boolean):void => {
        setCompanyAdded(status)
    }

    const AddOrUpdateUser = async (data: NDAType) => {
        if (id) {
            const file = data.file ? data.file : requestedUserById.ndaDocumentId

            const newUpdatedUser = await updateExistingUser(data.firstName, data.surname, data.email, data.company, data.rbu, data.level, data.requestor, id, file)
            if (newUpdatedUser?.status === 200) {
                window.location.href = '/'
            }

        } else {
            const newRequestedUser = await postNewUser(data.firstName, data.surname, data.email, data.company, data.rbu, data.level, data.requestor, data.file)
            if (newRequestedUser?.status === 200) {
                window.location.href = '/'
            }
        }
    }

    const onSubmit = (data: NDAType) => {
        if (isDataValid(data)) {
            setButtonDisabled(true)
            if (data.level.includes("Basic")) {
                AddOrUpdateUser(data);
            }
            else {
                if (!data.file) {
                    Modal.error({
                        content: "Missing NDA File"
                    })
                } else {
                    AddOrUpdateUser(data)
                }
            }
        } else {
            Modal.error({
                content: 'Missing RBU or Level or Company'
            });

        }

    };

    const downloadNda = async (ndaId: string) => {
        const blobData = await downloadNDADocument(ndaId);
        const fileName = blobData?.headers['content-disposition'].split('filename=')[1].split(';')[0];
        const downloadableFile = new Blob([blobData?.data], {
            type: blobData?.data.type
        });
        saveAs(downloadableFile, fileName)
    }

    const getData = async () => {
        setLoading(true);
        let actualData = await getRequestedUserById(id);
        isProdEnv && appInsights.trackTrace({ message: 'Data retrieved Successfully', severityLevel: SeverityLevel.Information });

        if (actualData) {
            setLoading(false);
            setRequestedUserById(actualData);
        }
    };

    const getRBU = async () => {
        setLoading(true);
        let rBUList = await getRbuList();
        let uniqueRBUList = rBUList.filter((rbu:any) =>rbu!=="UNKNOWN" ).sort();
        if (rBUList)
            setLoading(false)
        let rBU: SelectOptionType[] = [];
        uniqueRBUList.forEach((element: string) => {
            rBU.push({ label: element, value: element })
        });

        setRbus(rBU);
    }
    const getCompany = async () => {
        if (rBUValue) {
            if (!firstRender.current) {
                methods.setValue('company', '');
            }

            let companyList = await getCompanyList(rBUValue);

            let uniqueCompanyList = companyList.filter((value: any, index: any, array: any) => array.indexOf(value) === index).sort((a:string,b:string)=> a.localeCompare(b, undefined, {sensitivity: 'base'}));
            

            let companyObject: SelectOptionType[] = [];

            uniqueCompanyList.forEach((element: string) => {
                companyObject.push({ label: element, value: element })
            });
            setCompanies(companyObject);
            firstRender.current = false
        }

    };

    const getLevels = async () => {
        let levelList = await getLevelsList();
        let uniqueLevelList = levelList.filter((level:any) =>level!=="RD").sort();
        let level: SelectOptionType[] = [];
        uniqueLevelList.forEach((element: string) => {
            level.push({ label: element, value: element })
        });
        setLevels(level);
    }


    useEffect(() => {
        if (id) {
            getData();
        }
        getLevels();
        getRBU();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        getCompany();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rBUValue, companyAdded])

    return (
        <>
            {loading ? <Spin tip="Loading" size="large">
                <div className="content" />
            </Spin> :
                <Content className="site-layout" style={{ padding: '0 50px', marginTop: '40px' }}>

                    <div className='flex-container'>
                        <div className='flex-item'>
                            <a href='/'> <span className='text-primary'><LeftOutlined />Back</span></a>
                        </div>

                    </div>

                    <form className="form" onSubmit={methods.handleSubmit(onSubmit)}>
                        <FormProvider {...methods}>
                            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                <Col className="gutter-row" span={12}>
                                    <InputField name="requestor" label="Requestor Email" value={id && requestedUserById?requestedUserById.requester: requestorEmail} required maxLength={255} readonly={true} />
                                </Col>
                                <Col className="gutter-row" span={12}>
                                    <SelectField
                                        name="level"
                                        label="level"
                                        value={requestedUserById?.roles }
                                        options={levels}
                                    />
                                </Col>

                            </Row>
                            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>

                                <Col className="gutter-row" span={12}>
                                    <SelectField
                                        name="rbu"
                                        label="RBU"
                                        value={requestedUserById?.rbu}
                                        options={rbus}

                                    />
                                </Col>
                                <Col className="gutter-row" span={9}>
                                    <SelectField
                                        name="company"
                                        label="Company"
                                        value={requestedUserById?.company}
                                        options={companies}

                                    />
                                </Col>
                                <Col className="gutter-row" span={3}>
                                    <AddCompany rbuName={rBUValue} isCompanyAdded = {isCompanyAdded} />
                                </Col>
                            </Row>

                            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                <Col className="gutter-row" span={12}>
                                    <InputField name="firstName" label="First Name" value={requestedUserById?.name} required maxLength={255} />
                                </Col>
                                <Col className="gutter-row" span={12}>
                                    <InputField name="surname" label="Surname" value={requestedUserById?.surname} required maxLength={255} />
                                </Col>
                            </Row>
                            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                <Col className="gutter-row" span={12}>
                                    <InputField name="email" label="Email" value={requestedUserById?.email} required maxLength={255} readonly={id ? true : false} />
                                </Col>
                                <Col className="gutter-row" span={12}>
                                    <TextAreaField name="remarks" label="Remarks" value={requestedUserById?.remarks} />
                                </Col>
                            </Row>

                            {(!id || !hasRoleChanged) && !isLevelBasic &&
                                <FileUpload name="file" label="NDA File" value={requestedUserById?.file} multiple= {false} />

                            }

                            <div className='flex-container'>
                                <div className='flex-item'>

                                    {canApprove && hasRoleChanged && !isLevelBasic &&
                                        <Button
                                            icon={<CloudDownloadOutlined />}
                                            type="primary"
                                            className='button-primary mb-10'
                                            onClick={() => downloadNda(requestedUserById.ndaDocumentId)}>
                                            Download NDA
                                        </Button>


                                    }
                                </div>
                                <div className='flex-item'>
                                    <Button
                                        icon={<UserAddOutlined />}
                                        type="primary"
                                        className={`button-primary mb-10`}
                                        disabled={id ?!isOriginalRequestor:isButtonDisabled}
                                        
                                        onClick={methods.handleSubmit(onSubmit)}>
                                        {id ? 'Update User' : 'Request New User'}
                                    </Button>
                                </div>
                            </div>

                        </FormProvider>
                    </form>

                </Content>
            }
        </>
    );
};

export default NDA;

